/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import LazyLoad from 'react-lazyload';
import styled from "styled-components";
import GlobalStyle from "../utils/GlobalStyles";
import { graphql } from "gatsby";

// importing custom components.
import Layout from "../components/Layout";
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";

// importing custom containers.
import BestSellingProducts from "../containers/DropContainer";
import AboutUs from "../containers/FlowContainer";
import Brewery from "../containers/SpillContainer";
import BeerFestivals from "../containers/EventsContainer";
import Following from "../containers/FollowContainer";
import Carousel from "../containers/CarouselContainer";
import SEO from "../seo";

// importing bootstrap
import "bootstrap/dist/css/bootstrap.min.css"

// importing Fonts
import "../../static/fonts/stylesheet.css"

// styled components css
const CarouselContainer = styled.div`
display: flex;
flex-direction: row;
`;

// [Drop Container - Best Selling Products]
const DropContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// [Flow Container - About Us]
const FlowContainer = styled.div``;

// [Spill Container - Brewery]
const SpillContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// [Events Container - Beer Festivals]
const EventsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;



export default ({ data }) => {

  const metaData = data.allContentfulPage.nodes[0]

  // rendering components
  return (
    <Layout id="slidingPane">
      <SEO title={metaData.title} description={metaData.metaDescription} />
      <GlobalStyle />
      <CustomHeader />
      <CarouselContainer>
        <Carousel />
      </CarouselContainer>
      <DropContainer id="dropContainer">
        {/* <LazyLoad> */}
        <BestSellingProducts />
        {/* </LazyLoad> */}
      </DropContainer>
      <FlowContainer id="flowContainer">
        <LazyLoad>
          <AboutUs />
        </LazyLoad>
      </FlowContainer>
      <SpillContainer id="spillContainer">
        <LazyLoad>
          <Brewery />
        </LazyLoad>
      </SpillContainer>
      <EventsContainer id="eventsContainer">
        <LazyLoad>
          <BeerFestivals />
        </LazyLoad>
      </EventsContainer>
      <LazyLoad>
        <Following />
      </LazyLoad>
      <CustomFooter />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulPage(filter: {identifier: {eq: "home"}}) {
      nodes {
        title
        metaDescription
      }
    }
  }
`
