/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from 'reactstrap';


// importing custom components.
import TabContent from "../components/TabContent";
import MobileTab from "../components/MobileTab";



const VisibleDesktop = styled.div`
    @media(min-width:600px){
        display:block;
    }
    @media(max-width:599px){
        display:none;
    }
`

const VisibleMobile = styled.div`
    @media(min-width:600px){
        display:none;
    }
    @media(max-width:599px){
        display:block;
    }
`

const CarouselContainer = () => {

  const data = useStaticQuery(graphql`
    {
      allContentfulPage(filter: {identifier: {eq: "home"}}) {
        nodes {
          title
          widgets {
            ... on ContentfulHeroHeaderWidget {
              id
              carouselSections: sections {
                title
                description
                buttonText
                backgroundColor
                buttonLink
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const widgets = data.allContentfulPage.nodes[0].widgets;
  const carouselSections = widgets[0].carouselSections;

  // declaring states
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [defaultLoading, setDefaultLoadingFlag] = useState(true);
  const [visibleArry, setVisibleArry] = useState([true, false, false, false]);
  const [isLeft, setIsLeft] = useState(false)

  const onClick = (tabNumber, left) => {
    setSelectedIndex(tabNumber);
    setDefaultLoadingFlag(false)
    setIsLeft(left)
    setVisibleArry(
      visibleArry.map((value, i) => (i <= tabNumber ? true : false))
    );
  };


  const selectedSection = carouselSections[selectedIndex];
  // rendering components
  return (
    <Container fluid style={{ padding: 0, margin: 0, width: '100%' }}>
      <VisibleDesktop>
        <TabContent
          carouselSections={carouselSections}
          content={selectedSection}
          defaultLoading={defaultLoading}
          isLeft={isLeft}
          onClick={onClick}
          previous={carouselSections[isLeft ? selectedIndex + 1 : selectedIndex - 1]}
          tabNo={selectedIndex}
          visibleArry={visibleArry}
        />
      </VisibleDesktop>
      <VisibleMobile>
        <Row style={{ marginTop: "8%" }}>
          {[0, 1, 2, 3].map(value => (
            <Col key={value} xs={6} style={{ padding: 0, margin: 0, width: '100%' }}>
              <MobileTab content={carouselSections[value]} />
            </Col>
          ))}
        </Row>
      </VisibleMobile>
    </Container>
  );
};

export default CarouselContainer;
