/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import LazyLoad from 'react-lazyload';
import "./_eventItem.scss";

const EventItem = ({ data }) => {
  // rendering components
  return (
    <div className="event-item-container">
      <LazyLoad height={200}>
        <img loading="lazy" className="event-item-top-img" src={data.image.file.url} width="auto" height="auto" />
      </LazyLoad>
      <div className="event-item-info-container">
        <div className="event-item-title-data">{data.date}</div>
        <div className="event-item-title">{data.title}</div>
        <div className="event-item-description">{data.description.description}</div>
        <a className="event-item-facebook" href={data.facebook} target="_blank">Facebook</a>
        <div className="event-item-location-container">
          <img src="../images/location@3x.png" className="event-item-location-img" />
          <div className="event-item-location-name">{data.location}</div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;