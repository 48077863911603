/* eslint-disable react/prop-types */
import React from 'react';
import styled from "styled-components";

// styled components
const ButtonContainer = styled.div`
width: 55px;
height: 680px;
display: flex;
align-items: center;
background-color: ${props => props.color};
cursor: pointer;
height: 540px;

@media(min-width: 1200px) {
    height: 680px;
}
@media only screen and (min-width: 376px) and (max-width: 768px) { 
    width: 44px;
    height: 540px;
}
`

const InvertedText = styled.div`
transform: rotate(90deg);
font-family: Bebas Neue;
@media only screen and (min-width: 376px) and (max-width: 768px) { 
    font-size: 14px;
}
`


const TabButton = ({ content, onClick, visible }) => {

    const bg = content.backgroundColor;


    // rendering components.
    return (
        visible ?
            <ButtonContainer color={bg} onClick={onClick} >
                <InvertedText>{content.title}</InvertedText>
            </ButtonContainer> : null
    )
};

export default TabButton;   