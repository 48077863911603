/* eslint-disable react/prop-types */
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import BlogItem from "../components/BlogItem";

const SpillContainer = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPage(filter: {identifier: {eq: "home"}}) {
        nodes {
          title
          widgets {
            ... on ContentfulTheSpill {
              id
              title
              subtitle {
                subtitle
              }
              theSpillSections: sections {
                title
                description {
                  description
                }
                image {
                  id
                  file {
                    url
                  }
                  fluid {
                    tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const widgets = data.allContentfulPage.nodes[0].widgets;
  const theSpillData = widgets[2];

  return (
    <Container style={{ paddingLeft: '25px', paddingRight: '25px' }}>
      <Row>
        <Col xl={1} />
        <Col xl={11} >
          <div className="home-spill-head-min">{theSpillData.title}</div>
          <div className="home-spill-head-max">{theSpillData.subtitle.subtitle}</div>
        </Col>
      </Row>
      <div className="home-spill-desktop-visible">
        <Row>
          <Col xl={1} />
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <BlogItem data={theSpillData.theSpillSections[1]} />
          </Col>
          <Col className="home-spill-top-col" xl={5} lg={6} md={6} sm={12} xs={12}>
            <BlogItem data={theSpillData.theSpillSections[0]} />
          </Col>
          <Col xl={1} />
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <BlogItem data={theSpillData.theSpillSections[3]} />
          </Col>
          <Col className="home-spill-top-col" xl={5} lg={6} md={6} sm={12} xs={12} >
            <BlogItem data={theSpillData.theSpillSections[2]} />
          </Col>
        </Row>
      </div>
      <div className="home-spill-mobile-visible">
        <Row>
          <Col xl={1} />
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <BlogItem data={theSpillData.theSpillSections[1]} />
          </Col>
          <Col className="home-spill-top-col" xl={5} lg={6} md={6} sm={12} xs={12} >
            <BlogItem data={theSpillData.theSpillSections[3]} />
          </Col>
          <Col xl={1} />
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <BlogItem data={theSpillData.theSpillSections[0]} />
          </Col>
          <Col className="home-spill-top-col" xl={5} lg={6} md={6} sm={12} xs={12} >
            <BlogItem data={theSpillData.theSpillSections[2]} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SpillContainer;