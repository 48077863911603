/* eslint-disable react/prop-types */
import React, { useState, createRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import lottie from 'lottie-web';
import styled from "styled-components";
import Link from "gatsby-link";
import animatedButton from './animated/button.json';
import { Container, Row, Col } from "reactstrap";
import "./_containers.scss"

// importing custom components
import ProductListItem from "../components/ProductListItem";
import button from "../../static/images/showall_btn2.png";

import AliceCarousel from "react-alice-carousel";
import SlidingPane from "../components/SlidingPanel";
import "react-alice-carousel/lib/alice-carousel.css";

// styled componets css
const VisibleDesktop = styled.div`
    display:block;

    @media(max-width:599px){
        display:none;
    }
`

const VisibleMobile = styled.div`
  display:none;
  
  @media(max-width:599px){
    display:block;
  }
`

const DropContainer = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProduct(limit: 4, filter: {image: {id: {ne: null}}}) {
        nodes {
          id
          title
          brewery
          price
          alcohol_percentage
          packaging
          description {
            description
          }
          type
          image {
            id
            fluid(maxWidth: 613) {
              src
              srcSet
              srcWebp
              srcSetWebp
            }
            file {
              url
            }
          }
        }
      }
    }
  `)
  const content = data.allContentfulProduct.nodes;

  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  let animationContainer = createRef();

  const [isInfoPaneOpen, setIsInfoPaneOpen] = useState(false);


  const responsive = {
    0: {
      items: 2
    }
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animatedButton
    });
    return () => anim.destroy();
  }, [lottie, animationContainer]);

  return (
    <Container style={{ marginTop: 120 }} className="home-drop-container">
      <h3>The Drop</h3>
      <h1>Featured Products</h1>
      <Row>
        {content.map((item, index) => (
          <Col lg={4} xl={3} md={6} sm={6} key={item.id}>
            <VisibleDesktop>
              <ProductListItem
                key={item.id}
                data={item}
                onClick={() => {
                  setSelectedProductIndex(index);
                  setIsInfoPaneOpen(true);
                }}
              />
            </VisibleDesktop>
          </Col>
        ))}
      </Row>
      <VisibleMobile style={{ marginTop: 50 }}>
        <div style={{ width: 630 }}>
          <AliceCarousel
            duration={400}
            dotsDisabled
            buttonsDisabled
            infinite={false}
            startIndex={0}
            fadeOutAnimation={true}
            mouseDragEnabled={true}
            playButtonEnabled={false}
            responsive={responsive}
          >
            {[...content, []].map((item, index) =>
              index === 4 ? (
                <div key={index} />
              ) : (
                  <ProductListItem
                    key={item.id}
                    data={item}
                    onClick={() => {
                      setSelectedProductIndex(index);
                      setIsInfoPaneOpen(true);
                    }}
                  />
                )
            )}
          </AliceCarousel>
        </div>
      </VisibleMobile>
      <Row className="justify-content-center">
        <Col lg={3} md={6} sm={10}>
          <VisibleDesktop>
            <div className="home-drop-container-btn-wrapper">
              <Link
                to="/products/all"
                style={{
                  textDecoration: "none",
                  color: "black",
                  textAlign: "center"
                }}
              >
                <div className="animation-container" ref={animationContainer} />
              </Link>
            </div>
          </VisibleDesktop>

          <VisibleMobile>
            <div className="home-mob-drop-container-btn-wrapper" style={{ backgroundImage: `url(${button})` }}>
              <div className="home-mob-drop-container-btn-text">
                <Link
                  to="/products/all"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textAlign: "center"
                  }}
                >
                  Show All Products
              </Link>
              </div>
            </div>
          </VisibleMobile>
        </Col>
      </Row>
      <SlidingPane
        product={content[selectedProductIndex]}
        isInfoPaneOpen={isInfoPaneOpen}
        setIsInfoPaneOpen={setIsInfoPaneOpen}
      />
    </Container>
  );
};

export default DropContainer;
