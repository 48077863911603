/* eslint-disable react/prop-types */
import React, { useState, createRef, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import lottie from 'lottie-web';
import Accordion from "../components/Accordion";
// importing images
import flowAnimatedImage from "../../static/animated-images/the-flow.json";
import markImg from "../../static/images/mark.svg";
// importing grid system
import { Container, Row, Col } from "reactstrap";
import "./_containers.scss";


const FlowContainer = () => {

  const data = useStaticQuery(graphql`
    {
      allContentfulPage(filter: {identifier: {eq: "home"}}) {
        nodes {
          title
          widgets {
            ... on ContentfulTheFlow {
              id
              title
              subtitle {
                subtitle
              }
              image {
                fixed(width:388, height:447) {
                  ...GatsbyContentfulFixed
                }
                file {
                  url
                }
              }
              theFlowSections: sections {
                id
                title
                description {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const widgets = data.allContentfulPage.nodes[0].widgets;
  const theFlowData = widgets[1];


  const [statusArry, setStatusArry] = useState([true, false, false]);
  let animationContainer = createRef();

  const handleOnClick = index => {
    setStatusArry(
      statusArry.map((value, i) => (i === index ? !value : false))
    );
  }

  useEffect(() => {
    if (!animationContainer) {
      return
    }
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: flowAnimatedImage
    });
    return () => anim.destroy()
  }, [animationContainer]);

  return (
    <div id="flow">
      <div className="home-flow-container">
        <Container style={{ width: "100%", minHeight: '870px', padding: '0 0 0 0' }}>
          <Row justify="center">
            <div
              className="home-flow-col-1"
              lg={5}
              xl={5}
              md={6}
              sm={12}
            >
              <div className="home-flow-tab-visible">
                <div className="home-flow-image-container">
                  <div className="home-flow-image-wrapper">
                    <Img fixed={theFlowData.image.fixed} loading="lazy" className="lady-img" />
                    <img
                      src={markImg}
                      className="stamp-img"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Col className="home-flow-col-2 offset-lg-1" lg={6} xl={6} md={5} sm={12} style={{ position: 'relative' }}>
              <div className="home-blog-container">
                <div className="home-blog-head-min">{theFlowData.title}</div>
                <div className="home-blog-head-max">{theFlowData.subtitle.subtitle}</div>
                {theFlowData.theFlowSections.map((item, index) => (
                  <Accordion key={item.id} onClick={() => handleOnClick(index)} data={item} isOpen={statusArry[index]} />
                ))}
              </div>
            </Col>
            <Col
              lg={6}
              xl={6}
              md={6}
              sm={12}
              style={{ marginTop: 100, marginBottom: 100 }}
            >
              <div className="home-flow-mob-visible">
                <div className="home-flow-mob-image-wrapper">
                  <Img className="lady-img" loading="lazy" fixed={theFlowData.image.fixed} />
                  <img
                    style={{ left: '-7px', top: '-40px' }}
                    src={markImg}
                    loading="lazy"
                    className="stamp-img"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row justify='center'>
            <Col lg={7} xl={7} md={7} style={{ paddingLeft: 0, paddingRight: 0 }} />
            <Col lg={5} xl={5} md={5} >
              <div className="home-flow-tab-visible">
                <div className="home-flow-animation-container" ref={animationContainer} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default FlowContainer;