/* eslint-disable react/prop-types */
import React from 'react';
import styled from "styled-components";
import scrollTo from 'gatsby-plugin-smoothscroll';

const TabContent = ({ content }) => {

    const bg = content.backgroundColor;
    // styled compoent css
    const Container = styled.div`
        display: flex;
        background-color: ${props => props.color};
        flex-direction: column;
        width: 100%;
        height: 240px;
        justify-content: space-between;
        padding-bottom: 20px;
    `

    const ContentImg = styled.img`
        height: 134px;
        width: 120px;
        align-self: center; 
        margin-top: 40px;
    `

    const Ttab = styled.div`
        font-family: Bebas Neue;
        font-size: 18px;
        margin-top: 20px;
        color: #1c1c1c;
        margin-top: 24px;
        padding-left: 30px;
        // text-align: center;
        cursor: pointer;
    `

    // rendering components
    return (
        <Container color={bg} onClick={() => {
            scrollTo(`#${content.buttonLink}`)
        }}>
            <ContentImg src={content.image.file.url} />
            <Ttab>{content.title}</Ttab>
        </Container>
    )
};

export default TabContent;