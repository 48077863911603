/* eslint-disable react/prop-types */
import React, { createRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import lottie from 'lottie-web';
import spillAnimatedImage from "../../static/animated-images/the-spill.json";
import EventItem from "../components/EventItem";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./_containers.scss"

// styled componets css


const EventsContainer = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulEvent(sort: {fields: date}) {
        nodes {
          title
          description {
            description
          }
          image {
            file {
              url
            }
          }
          date(formatString: "MMMM DD, YYYY")
          location
          facebook
        }
      }
    }
  `)
  const events = data.allContentfulEvent.nodes;

  let animationContainer = createRef();

  const responsive = {
    0: {
      items: 4
    },
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: spillAnimatedImage
    });
    return () => anim.destroy();
  }, [lottie, animationContainer]);


  return (
    <div className="home-event-container">
      <div className="home-event-head-min">Events</div>
      <div className="home-event-head-max">Craft BEER Festivals</div>
      <div className="home-event-desktop-visible">
        <div className="home-event-top-deco-anim" ref={animationContainer} />
      </div>
      <div className="home-event-fest-container">
        <AliceCarousel
          duration={400}
          dotsDisabled
          infinite={false}
          startIndex={0}
          fadeOutAnimation={true}
          mouseDragEnabled={true}
          playButtonEnabled={false}
          buttonsDisabled={true}
          responsive={responsive}
          mouseTrackingEnabled={true}
        >
          {events.map(item => (
            <EventItem key={item.id} data={item} />
          ))}
        </AliceCarousel>
      </div>
    </div>
  );
};

export default EventsContainer;