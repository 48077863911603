/* eslint-disable no-unused-vars */
import React, { useEffect, createRef } from "react";
import styled, { keyframes } from "styled-components";
import lottie from 'lottie-web';
import PropTypes from "prop-types";
import scrollTo from "gatsby-plugin-smoothscroll";
import animatedDropImage from '../../../static/animated-images/the-drop.json';
import animatedFlowImage from '../../../static/animated-images/the-flow.json';
import animatedSpillImage from '../../../static/animated-images/the-spill.json';
import animatedEventsImage from '../../../static/animated-images/the-events.json';
import button from "../../../static/images/button.png";
import TabButton from "../TabButton";
import { Container, Row, Col } from "reactstrap";

// animation
import { slideInRight, slideInLeft } from "react-animations";

// stylesheet
import "./_tabContent.scss";

// styled compoent css}
const animationLeft = keyframes`${slideInLeft}`;
const animationRight = keyframes`${slideInRight}`;


const DefaultDiv = styled.div`
  z-index:-99;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`;


const AnimatedDivLeft = styled.div`
  animation: 1s ${animationLeft};
  background-color: green;
  z-index:-99;
  // width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const AnimatedDivRight = styled.div`
  animation: 1s ${animationRight};
  width: 100%;
  z-index:-99;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const AnimatedDivLeftFast = styled.div`
  animation: 1s ${animationLeft};
  z-index:-99;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const AnimatedDivRightFast = styled.div`
  animation: 1s ${animationRight};
  width: 100%;
  z-index:-99;
  display: flex;
  flex-direction: row;
  flex: 1;
`;



const PreviousTabContent = (previous, isLeft, left, carouselSections, visibleArry, tabNo, defaultLoading) => {
  return (
    <div style={{
      position: 'absolute',
      left: left,
      top: '0',
      display: 'flex',
      flexDirection: 'row',
      zIndex: '-100',
      width: '100%'
    }}>
      <div className="button-container">
        {carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={visibleArry[index] && (index !== tabNo || index === 0)}
          />
        ))}
      </div>
      <div className="button-container">
        {!isLeft && carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={visibleArry[index] && (index === tabNo && index !== 0)}
          />
        ))}
      </div>
      <Container
        className="carousel-container"
        fluid
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: previous.backgroundColor,
          width: "100%",
        }}
      >
        <Row
          className="carousel-row"
          align="center"
          style={{
            width: "100%",
          }}
        >
          <Col md={6} lg={6} xl={6} className="mt-auto mb-auto text-left">
            <div className="carousel-title">
              {" "}
              {previous.title}{" "}
            </div>
            <div className="carousel-description">{previous.description}</div>
            <div className="carousel-btn-wrapper" style={{ backgroundImage: `url(${button})` }} >
              <div className="carousel-btn-text"> {previous.buttonText} </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="button-container">
        {isLeft && carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={visibleArry[index] && index === tabNo && isLeft && !defaultLoading && index !== 0}
          />
        ))}
      </div>
      <div className="button-container">
        {carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={!visibleArry[index] && index !== tabNo}
          />
        ))}
      </div>
    </div>
  )
}

const TabContent = (props) => {
  let dropAnimationContainer = createRef();
  let flowAnimationContainer = createRef();
  let spillAnimationContainer = createRef();
  let eventsAnimationContainer = createRef();

  useEffect(() => {
    if (!flowAnimationContainer || !spillAnimationContainer || !eventsAnimationContainer) {
      return
    }

    lottie.loadAnimation({
      container: dropAnimationContainer.current, // current instance of our container!
      animationData: animatedDropImage, // animation file!
      renderer: "svg",
      loop: true,
      autoplay: true
    });

    lottie.loadAnimation({
      container: flowAnimationContainer.current, // current instance of our container!
      animationData: animatedFlowImage, // animation file!
      renderer: "svg",
      loop: true,
      autoplay: true
    });

    lottie.loadAnimation({
      container: spillAnimationContainer.current, // current instance of our container!
      animationData: animatedSpillImage, // animation file!
      renderer: "svg",
      loop: true,
      autoplay: true
    });

    lottie.loadAnimation({
      container: eventsAnimationContainer.current, // current instance of our container!
      animationData: animatedEventsImage, // animation file!
      renderer: "svg",
      loop: true,
      autoplay: true
    });

  }, [dropAnimationContainer, flowAnimationContainer, spillAnimationContainer, eventsAnimationContainer]);

  const { content, carouselSections, onClick, visibleArry, defaultLoading, previous, tabNo, isLeft } = props
  let AnimationComp = true;
  if (defaultLoading) {
    AnimationComp = DefaultDiv;
  } else {
    if (content.title.toLowerCase().indexOf("flow") > 0) {
      AnimationComp = isLeft ? AnimatedDivLeftFast : AnimatedDivRightFast;
    } else {
      AnimationComp = isLeft ? AnimatedDivLeft : AnimatedDivRight;
    }
  }

  const filterSections = [];
  carouselSections.map((section, index) => (visibleArry[index] && filterSections.push(true)));
  const count = filterSections.length;
  const left = isLeft ? `${55 * (count - 1)}px` : `-55px`;

  // console.log(left, count)

  return (
    <div className="covered-div">
      <div className="button-container">
        {carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={visibleArry[index] && (index !== tabNo || index === 0)}
            onClick={e => onClick(index, true)}
          />
        ))}
      </div>
      {previous && PreviousTabContent(previous, isLeft, left, carouselSections, visibleArry, tabNo, defaultLoading)}
      <AnimationComp key={tabNo} backgroundColor={content.backgroundColor}>
        <div className="button-container">
          {isLeft === false && carouselSections.map((section, index) => (
            <TabButton
              key={section.id}
              content={section}
              visible={visibleArry[index] && (index === tabNo && index !== 0)}
              onClick={e => onClick(index, true)}
            />
          ))}
        </div>
        <Container
          className="carousel-container"
          fluid
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: content.backgroundColor,
            width: "100%",
          }}

        >
          {/* {console.log(tabNo === 1)} */}
          <Row
            className="carousel-row"
            align="center"
            style={{
              width: '100%',
            }}
          >
            <Col md={6} lg={6} xl={6} className="mt-auto mb-auto text-left">
              <div className="carousel-title">
                {" "}
                {content.title}{" "}
              </div>
              <div className="carousel-description">{content.description}</div>
              <div className="carousel-btn-wrapper" style={{ backgroundImage: `url(${button})` }}
                onClick={() => {
                  scrollTo(`#${content.buttonLink}`);
                }}
              >
                <div className="carousel-btn-text"> {content.buttonText} </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={6}>
              {tabNo === 0 && (
                <div className="animation-container" ref={dropAnimationContainer} />
              )}
              {tabNo === 1 && (
                <div className="animation-container" ref={flowAnimationContainer} />
              )}
              {tabNo === 2 && (
                <div className="animation-container" ref={spillAnimationContainer} />
              )}
              {tabNo === 3 && (
                <div className="animation-container" ref={eventsAnimationContainer} />
              )}
            </Col>
          </Row>

        </Container>
        <div className="button-container">
          {carouselSections.map((section, index) => (
            <TabButton
              key={section.id}
              content={section}
              visible={visibleArry[index] && index === tabNo && isLeft && !defaultLoading && index !== 0}
              onClick={e => onClick(index, false)}
            />
          ))}
        </div>
      </AnimationComp>
      <div className="button-container">
        {carouselSections.map((section, index) => (
          <TabButton
            key={section.id}
            content={section}
            visible={!visibleArry[index] && index !== tabNo}
            onClick={e => onClick(index, false)}
          />
        ))}
      </div>
    </div>

  );
};

TabContent.propTypes = {
  content: PropTypes.object.isRequired,
  carouselSections: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  visibleArry: PropTypes.array,
  defaultLoading: PropTypes.bool.isRequired,
  previous: PropTypes.object,
  tabNo: PropTypes.number,
  isLeft: PropTypes.bool,
}

export default TabContent;
